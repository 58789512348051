import React from 'react';


const QuickReply = (props) => {
    if (props.reply.title) {
        return (
            <div className="slider__card">
                <a href="/" className="slider__link"
                    onClick={(event) =>
                        props.click(
                            event,
                            props.reply.title,
                            props.reply.title
                        )
                    }>
                    <span className="slider__img">
                        <img src={`${process.env.REACT_APP_BASEAPI}${props.reply.image}`} alt="icon" />
                    </span>

                    <p className="slider__text">
                        {props.reply.title}
                    </p>

                </a>
            </div>
        )
    }
    else {
        let imgSource
        if (props.reply === 'Order Info') {
            imgSource = `${process.env.REACT_APP_BASEAPI}/images/order_info.svg`
        }

        else if(props.reply ==='Online Ordering Help')
        {
            imgSource=`${process.env.REACT_APP_BASEAPI}/images/online_ordering_help.svg`
        }
        else{
          
                imgSource=`${process.env.REACT_APP_BASEAPI}/images/something_else.svg`
           
        }
        return (
            <div className="slider__card">
                <a href="/" className="slider__link"
                    onClick={(event) =>
                        props.click(
                            event,
                            props.reply,
                            props.reply
                        )
                    }>
                         <span className="slider__img">
                    <img src={imgSource} alt="icon" />
                    </span>
                    <p className="slider__text">
                        {props.reply}
                    </p>

                </a>
            </div>
        )
    }
}
export default React.memo(QuickReply);