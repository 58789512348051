import React from 'react';
import Chatbot from './Chatbot/Chatbot';


const colortheme2=()=>
{
  document.querySelector('body').classList.toggle('skyblue');
  document.querySelector('body').classList.remove('orangetheme');
  document.querySelector('body').classList.remove('yellowtheme');
}

const App = () =>
  (<>
 {window.onload=colortheme2}

 
  <Chatbot />

   
    </>)
export default App;