import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
const recaptchaRef = React.createRef();
const GetRecaptcha = ()=>{

const onChange=(value) =>{
    console.log("Captcha value:", value);
  }
return <form onSubmit={() => { recaptchaRef.current.execute(); }}>
    <ReCAPTCHA
      ref={recaptchaRef}
      size="invisible"
      sitekey={process.env.REACT_APP_GOOGLE_KEY}
      
      onChange={onChange}
    />
  </form>
}
export default GetRecaptcha;