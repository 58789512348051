import React, { Component } from "react"
import ListBox from './ListBox';


class ListBoxOptions extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(event, payload, text) {
        this.props.replyClick(event, payload, text);
        
    }
    renderQuickReply(reply, i) {
        
        return <ListBox key={i} click={this.handleClick} reply={reply} orderNumber={reply.orderNumber} PONumber={reply.PONumber} orderDate={reply.orderDate} title={this.props.list_title} />
    }
    renderQuickReplies(quickReplies) {
        if (quickReplies) {
            return quickReplies.map((reply, i) => {
                return this.renderQuickReply(reply, i);
            })
        }
        else {
            return null
        }
    }
    render() {
        return (
            <div class="chat__text chat__text--left">
                 {/* <p class="order__text"><b>{this.props.list_title}</b></p> */}
                <ul className="order__menu">
                    
                    {this.renderQuickReplies(this.props.payload)}

                </ul>

            </div>
        )
    }
}
export default ListBoxOptions;
