import React from 'react'
const Loader=(props)=>
{
   return <div className="chat__textwrap">
        <div className="bot bot--backcolor">
          <img src="./Images/chatbot.svg" alt="" className="bot__img bot__icon" />
          {props.icon?<img src="./Images/real_pic.jpg" alt="" className="bot__img bot__real" />:<img src="./Images/bot_1.svg" alt="" className="bot__img bot__real" />}
          
        </div>
    <div className="chat__text chat__text--left">
        <span className="dot"></span>
        <span className="dot"></span>
        <span className="dot"></span>
    </div>
</div>
}
export default React.memo(Loader);