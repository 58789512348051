import React from "react"
import QuickReply from './QuickReply';

const Quickreplies =(props)=>{
  
const handleClick=(event, payload, text)=> {
  // event.preventDefaul();
    props.replyClick(event, payload, text);
  }


const  renderQuickReply=(reply, i)=> {
    return <QuickReply key={i} click={handleClick} reply={reply} />
  }


 const renderQuickReplies=(quickReplies)=> {
    if (quickReplies) {
      return quickReplies.map((reply, i) => {
        return renderQuickReply(reply, i);
      })
    }

  }

    return (
      <div className="slider">
        {renderQuickReplies(props.payload)}
      </div>

  
)

}
export default React.memo(Quickreplies) ;
