import React from 'react';


const ListBox = (props) => {

    if (props.reply) {

        if (props.reply.C_Address) {
            return (<li class="order__list"
                onClick={(event) =>
                    props.click(
                        event,
                        props.reply.C_Address,
                        props.reply.C_Address
                    )
                }>
                    <div class="order__list-wrap">
                <p class="order__no">{props.reply.C_Address}</p>
                </div>
            </li>

            )
        }

        else if (props.reply.invoiceNumber) {
            return (<li class="order__list"
                onClick={(event) =>
                    props.click(
                        event,
                        props.reply.invoiceNumber,
                        props.reply.invoiceNumber
                    )
                }>
                         <div class="order__list-wrap">
                <p class="order__no">{props.reply.invoiceNumber}</p>
                <p class="order__no">{props.PONumber}</p>
                <p class="order__date">{props.orderDate}</p>
                </div>
            </li>

            )
        }
        else {
            return (<li class="order__list"
                onClick={(event) =>
                    props.click(
                        event,
                        props.reply.orderNumber,
                        props.reply.orderNumber
                    )
                }>
                     <div class="order__list-wrap">
                <p class="order__no">{props.orderNumber}</p>
                <p class="order__no">{props.PONumber}</p>
                <p class="order__date">{props.orderDate}</p>
                </div>
            </li>

            )
        }

    }

    else {
        return (<a href="/">
            {props.orderNumber}-{props.PONumber}-{props.orderDate}</a>)
    }
}
export default ListBox;