import React, { Component } from 'react';
import axios from 'axios';
import Message from './Message'
import QuickReplies from './QuickReplies';
import ListOptions from './ListBoxOptions'
import Loader from './Loader';
import DatePickerComponant from "./DatePicker";
import dateFormat from 'dateformat';
import io from 'socket.io-client';
import GetRecaptcha from './GetRecaptcha';

const socket = io(`${process.env.REACT_APP_BASEAPI}/customer`);
class Chatbot extends Component {
    messagesEnd;

    constructor(props) {
        super(props)
        this.state = {
            messages: [],
            showBot: false,
            isLoading: true,
            toDate: new Date(),
            fromDate: new Date(),
            showDatePicker: false,
            socketOn: false,
            onCustomerSupport: true,
            scount: 1,
            ncount: 1,
            pcount: 1,
            user_input: false,

        }
        this._handleInputKeyPress = this._handleInputKeyPress.bind(this)
        this._handleSocketOn = this._handleSocketOn.bind(this)

    }
    //Agent Request to Socket Server  
    AgentRequest = () => {

        let msg = "Customer Support"
        this.send_socket_event(msg)
        this.setState({ socketOn: true })
    }
    //duplicate values in chatbot
    duplicate = (data, key) => {

        return [
            ...new Map(
                data.map(x => [key(x), x])

            ).values()
        ]
    }


    //Send Event to socket server
    async send_socket_event(event) {

        let data = {
            msg: event,
            sessionId: sessionStorage.getItem('sessionId'),
            deviceId: localStorage.getItem('deviceId')
        }

        let deviceId = {
            deviceId: localStorage.getItem('deviceId')
        }
        axios.post(`${process.env.REACT_APP_BASEAPI}/get_customer_info`, deviceId).then(res => {
            this.setState({ user_input: true })
            if (res.data.isRegistered === false) {
                let cusotmerName;
                if (event === "Sales Inquiry") {
                    cusotmerName = "Sales Inquiry" + this.state.scount
                    this.setState({ scount: +1 })
                }
                else if (event === "New Customer") {
                    cusotmerName = "New Customer" + this.state.ncount
                    this.setState({ ncount: +1 })
                }
                else {
                    cusotmerName = "Prospect" + this.state.pcount
                    this.setState({ pcount: +1 })
                }
                let info = {
                    chat_request: event !== 'Sales Force Case' ? event : this.state.messages[this.state.messages.length - 1].msg,
                    address: "Not Registered",
                    email: "Not Provided",
                    latestSessionId: sessionStorage.getItem('sessionId'),
                    location: "Not Registered",
                    name: cusotmerName,
                    name2: "Not Provided",
                    previousEnquiry: null
                }
                data["customerInfo"] = info
                socket.emit('customer message', data);
            }
            else {
                let x = res.data
                x.chat_request = event !== 'Sales Force Case' ? event : this.state.messages[this.state.messages.length - 1].msg
                data.customerInfo = x
                socket.emit('customer message', data);
            }
        })


        let says = {}
        says = {
            speaks: `${process.env.REACT_APP_USERICON}`,
            msg: event !== 'Sales Force Case' ? event : this.state.messages[this.state.messages.length - 1].msg
        }

        this.setState({ messages: [...this.state.messages, says], isLoading: true, onCustomerSupport: false })
        socket.on('customer message', (msg) => {
            let icon
            if (msg === "Wait, while we transfer your chat to our customer service executive." ||
                msg === "Just wait ..." ||
                msg ===`Unfortunately, there is not a live agent available at this time. As soon as someone is \
                available they will reach out to you via your registered email. Please note, our business hours are Monday\
                 – Friday from 7 AM – 6 PM CST. If this is a medical gas emergency, please call 1-800-720-1563. \
                 <a href='http://www.esprigas.com/contact-us' target='_blank'>Contact Us</a>`)
                {
                icon = `${process.env.REACT_APP_BOTICON}`
                
            }
            else {
                icon = `${process.env.REACT_APP_AGENTICON}`
            }
            says = {
                speaks: icon,
                msg: msg,
                msg_values: []
            }

            let duplicate = this.duplicate([...this.state.messages, says], it => it.msg)
            this.setState({ messages:duplicate, isLoading: false })

        });
    }


    //function to send request to server
    async send_text_query(text) {

        if (text === 'New Customer' || text === 'Sales Inquiry' || text === 'human' || text === 'real person') {
            this.send_socket_event(text)
            this.setState({ socketOn: true, user_input: true })
        }

        else {
            let says = {}
            let contextName = `${sessionStorage.getItem('contextName') ? sessionStorage.getItem('contextName') : ''}`
            let sessionId = `${sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : ''}`
            let email = `${sessionStorage.getItem('user_email') ? sessionStorage.getItem('user_email') : ''}`
            let requestCount = `${localStorage.getItem('requestCount') ? localStorage.getItem('requestCount') : ''}`
            says = {
                speaks: `${process.env.REACT_APP_USERICON}`,
                msg: text,
                sessionId: sessionId,
                contextName: contextName,
                email: email,
                slug: text.toLowerCase().replace(/\s/g, '_'),
                deviceId: localStorage.getItem('deviceId'),
                requestCount: requestCount
            }

            let header = {
                headers: {
                    auth_header: `${process.env.REACT_APP_HEADER}`
                }
            }
            this.setState({ messages: [...this.state.messages, says], isLoading: true })
            await axios.post(`${process.env.REACT_APP_BASEAPI}/startchat`, says, header).then(res => {
                this.setState({ user_input: res.data.user_input })
                sessionStorage.setItem('contextName', res.data.contextName)
                if (sessionStorage.getItem('contextName') === 'awaiting_date_range') {
                    this.setState({ showDatePicker: true })
                }
                if (res.data.redirect_url) {
                    window.open(res.data.redirect_url, "_blank");
                }
                //save email in cookies
                if (res.data.email) {

                    sessionStorage.setItem('user_email', res.data.email)

                }
                if (res.data.requestCount) {
                    localStorage.setItem('requestCount', res.data.requestCount)
                }
                else if (!res.data.requestCount) {
                    localStorage.removeItem('requestCount')

                }
                if (res.data.chatTransfer === true) {
                    this.send_socket_event("Sales Force Case")
                    this.setState({ socketOn: true })
                }
                if (res.data.resp) {
                    let m = ''
                    let values = ''
                    let options
                    let list_title = ''

                    for (let msg of res.data.resp) {
                        if (msg.type === 'text') {
                            m = msg.title
                        }
                        else if (msg.type === 'list') {
                            options = msg.values
                            list_title = msg.title

                        }

                        else {
                            values = msg.values
                        }

                    }
                    says = {
                        speaks: `${process.env.REACT_APP_BOTICON}`,
                        msg: m,
                        msg_values: values,
                        msg_options: options,
                        list_title: list_title,
                        sessionId: sessionStorage.getItem('sessionId'),
                        contextName: sessionStorage.getItem('contextName'),
                        deviceId: localStorage.getItem('deviceId'),

                    }
                }

                if (!res.data.chatTransfer) {
                    this.setState({ messages: [...this.state.messages, says], isLoading: false })
                }

            })
        }

    }


    //send Event
    async send_event_query(event) {
        let newEvent = {
            msg: event,
            deviceId: localStorage.getItem('deviceId') ? localStorage.getItem('deviceId') : '',
            sessionId: sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '',
            slug: event.toLowerCase().replace(/\s/g, '_'),
            contextName: sessionStorage.getItem('contextName') ? sessionStorage.getItem('contextName') : '',
        }
        let header = {
            headers: {
                auth_header: `${process.env.REACT_APP_HEADER}`
            }
        }

        if (sessionStorage.getItem('contextName') === 'awaiting_date_range') {
            this.setState({ showDatePicker: true })
        }
        await axios.post(`${process.env.REACT_APP_BASEAPI}/startchat`, newEvent, header).then(res => {

            // save session in session storage
            if (!sessionStorage.getItem('sessionId')) {
                sessionStorage.setItem('sessionId', res.data.sessionId)
            }

            let says = {}
            let message = ''
            let values = ''
            let options
            let list_title = ''
            //save DeviceId in localStorage
            if (res.data.deviceId) {

                localStorage.setItem('deviceId', res.data.deviceId)
            }
            for (let msg of res.data.resp) {

                if (msg.type === 'text') {
                    message = msg.title
                }
                else if (msg.type === 'list') {
                    list_title = msg.title
                    options = msg.values
                }
                else {

                    values = msg.values
                }
                says = {
                    speaks: `${process.env.REACT_APP_BOTICON}`,
                    msg: message,
                    msg_options: options,
                    msg_values: values,
                    list_title: list_title,
                    sessionId: sessionStorage.getItem('sessionId'),
                    contextName: sessionStorage.getItem('contextName'),
                    deviceId: localStorage.getItem('deviceId'),
                    requestCount: localStorage.getItem('requestCount')

                }

                this.setState({ messages: [...this.state.messages, says], isLoading: false })
            }
            let duplicate = this.duplicate([...this.state.messages, says], it => it.msg)
            this.setState({ messages: duplicate, isLoading: false })

        })


    }


    async componentDidMount() {
        var hasStorage = function () {
            var mod,
                result;
            try {
                mod = new Date();
                localStorage.setItem(mod, mod.toString());
                result = localStorage.getItem(mod) === mod.toString();
                localStorage.removeItem(mod);
                return result;
            } catch (_error) {
                return false;
            }
        },
            hasCookies = function () {
                var cookieEnabled = navigator.cookieEnabled ? true : false;

                if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
                    document.cookie = 'testcookie';
                    cookieEnabled = (document.cookie.indexOf('testcookie') !== -1) ? true : false;
                }
                return cookieEnabled;
            };

        if (!hasStorage() || !hasCookies()) {
            let says = {
                speaks: `${process.env.REACT_APP_BOTICON}`,
                msg: "We don't support private/incognito mode. Enjoy amazing chat experience in normal browser mode!",
                msg_values: [],

            }
            this.setState({ messages: [...this.state.messages, says], isLoading: false })
        }
        else {
            let deviceId = {
                deviceId: localStorage.getItem("deviceId")
            }
            let chat = []
            await axios.post(`${process.env.REACT_APP_BASEAPI}/history_from_device_id`, deviceId).then(res => {
                sessionStorage.setItem("sessionId", res.data.sessionId)
                chat = res.data.chat
            })

            let says = {}
            if (chat === null) { }
            else {
                chat.map((item) => {
                    if (item.botResp) {
                        let values = []
                        says = {
                            speaks: `${process.env.REACT_APP_BOTICON}`,
                            msg: item.botResp,
                            msg_values: values,
                            sessionId: sessionStorage.getItem('sessionId'),
                            deviceId: localStorage.getItem('deviceId')
                        }
                        this.setState({ messages: [...this.state.messages, says], isLoading: false })
                    }
                    if (item.userResp) {
                        says = {
                            speaks: `${process.env.REACT_APP_USERICON}`,
                            msg: item.userResp,
                            sessionId: sessionStorage.getItem('sessionId'),
                            deviceId: localStorage.getItem('deviceId')
                        }
                        this.setState({ messages: [...this.state.messages, says], isLoading: true })
                    }
                    return item;
                })
            }
            let duplicate = this.duplicate([...this.state.messages, says], it => it.msg)
            this.setState({ messages: duplicate, isLoading: false })
            if (chat) {
                this.send_event_query(chat[chat.length - 1].userResp)
            }
            else { this.send_event_query('hi') }
        }
    }

    componentDidUpdate() {
        return this.messagesEnd.scrollIntoView({ behavior: "smooth" })
    }
    //Render Loader
    renderLoader = (message) => {
        let agent = false
        message.map((msg) => {
            if (msg.speaks === `${process.env.REACT_APP_AGENTICON}`) {
                agent = true
            }
            return agent
        })
        return <Loader icon={agent} />
    }

    //Render one message 
    renderOneMessage(message) {

        if (message.msg_options) {

            return (<div>
                <Message key={message.index} speaks={message.speaks} text={message.msg} changeIcon={this.state.changeIcon} />
                <ListOptions
                    list_title={message.list_title}
                    text={message.msg_options ? message.msg_options : null}
                    key={message.index}
                    replyClick={this._handleQuickReplyPayload}
                    speaks={message.speaks}
                    payload={message.msg_options}
                />
                <QuickReplies
                    text={message.msg_values ? message.msg_values : null}
                    key={message.index}
                    replyClick={this._handleQuickReplyPayload}
                    speaks={message.speaks}
                    payload={message.msg_values}
                />
            </div>)
        }
        else if (message.msg_values) {
        return (<div>
                <Message key={message.index} speaks={message.speaks} text={message.msg} />
                <QuickReplies
                    text={message.msg_values ? message.msg_values : null}
                    key={message.index}
                    replyClick={this._handleQuickReplyPayload}
                    speaks={message.speaks}
                    payload={message.msg_values}
                />
            </div>)
        }
        else if (message.msg && message.speaks === process.env.REACT_APP_USERICON) {
            return (
                <Message key={message.index} speaks={message.speaks} text={message.msg} />
            )
        }
    }
    //RENDER MESSAGES

    renderMessages(stateMessages) {
        if (stateMessages) {


            return stateMessages.map((message) => {

                return this.renderOneMessage(message)
            })
        }
        else { return null }
    }
    //HANDLE QUICK REPLIES
    _handleQuickReplyPayload = (event, payload, text) => {
        event.preventDefault();
        event.stopPropagation();
        let slug = text
        this.send_text_query(slug);

        // const removeElements = (elms) => elms.forEach(el => el.remove());
        // removeElements(document.querySelectorAll(".slider"));
        // if (document.querySelector("ul.order__menu") !== null)
        //     document.querySelector("ul.order__menu").parentElement.remove();
    }

    // //HANDLE SOCKET REQUESTS
    _handleSocketOn(e) {
        let currentComponent = this;
        let msg = e.target.value
        if (msg) {
            if (e.key === "Enter") {
                currentComponent.send_socket_event(msg)
                e.target.value = "";
            }
        }

    }
    //handle Socket Click Function

    _handleSocketOnClick = () => {
        let currentComponent = this;
        let msg = document.getElementById('m').value
        if (msg) {
            currentComponent.send_socket_event(msg)
            document.getElementById('m').value = "";
            document.querySelector('.footer__send').classList.remove('active');

        }
    }
    //validate Input Field
    handleValidation = (e) => {
        let isError = false;

        if (e.target.value === '' || e.target.value === ' ' || e.target.value === '  ') {

            isError = true
        }

        return isError;
    }

    _handleInputKeyPress(e) {

        document.querySelector('.footer__send').classList.add('active');
        let validate = this.handleValidation(e)
        if (!validate) {
            if (e.key === "Enter") {

                this.send_text_query(e.target.value);
                e.target.value = "";
                document.querySelector('.footer__send').classList.remove('active');
            }
        }
    }
    _handleClick = () => {
        let x = document.getElementById('m').value

        if (x) {
            this.send_text_query(x);
            document.getElementById('m').value = "";
            document.querySelector('.footer__send').classList.remove('active');
        }
    }
    handlefrom = date => {
        this.setState({ fromDate: date })

    }
    handleto = date => {
        this.setState({ toDate: date })

        let DateRange = `${dateFormat(this.state.fromDate, "yyyy-mm-dd")} to ${dateFormat(date, "yyyy-mm-dd")}`

        this.send_text_query(DateRange)
        this.setState({ showDatePicker: false })
    }

    render() {

        return (<>
            <GetRecaptcha />
            <header>
                <div className="header">
                    <div className="bot bot--border">
                        <img src="./Images/bot_1.svg" alt="" className="bot__icon" />
                        <img src="./Images/bot_1.svg" alt="" className="bot__real" />

                    </div>
                    <span className="bot__name">{process.env.REACT_APP_BOTNAME}</span>
                </div>
            </header>

            <main>
                {/* BOT MESSAGES */}
                {this.renderMessages(this.state.messages)}
                <div style={{ display: this.state.showDatePicker ? 'flex' : 'none' }} className="chat__text chat__date-card mt-5">
                    <div className="chat__date-wrap" >
                        <div className="chat__date">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" className="chat__icon">
                                <g id="Group_1213" data-name="Group 1213" transform="translate(-4 -2.5)">
                                    <path id="Path_104" data-name="Path 104" d="M16.37,4.121H15.5v-.5a1.158,1.158,0,0,0-2.316,0v.5H8.829v-.5a1.158,1.158,0,0,0-2.316,0v.5H5.63A1.618,1.618,0,0,0,4,5.713v9.2A1.622,1.622,0,0,0,5.645,16.5H16.355A1.612,1.612,0,0,0,18,14.908v-9.2A1.6,1.6,0,0,0,16.37,4.121Zm-2.5-.5a.451.451,0,0,1,.457-.442.461.461,0,0,1,.457.442V4.666a.451.451,0,0,1-.457.442.461.461,0,0,1-.457-.442Zm-6.657,0a.457.457,0,0,1,.914,0V4.666a.457.457,0,0,1-.914,0Zm10.1,11.288a.925.925,0,0,1-.944.914H5.645a.934.934,0,0,1-.944-.914V7.186H17.314Z" transform="translate(0 0)" fill="#7B7B7B" />
                                    <circle id="Ellipse_171" data-name="Ellipse 171" cx="0.958" cy="0.958" r="0.958" transform="translate(6.282 12.388)" fill="#7B7B7B" />
                                    <circle id="Ellipse_172" data-name="Ellipse 172" cx="0.958" cy="0.958" r="0.958" transform="translate(10.034 12.388)" fill="#7B7B7B" />
                                    <circle id="Ellipse_173" data-name="Ellipse 173" cx="0.958" cy="0.958" r="0.958" transform="translate(13.787 12.388)" fill="#7B7B7B" />
                                    <circle id="Ellipse_174" data-name="Ellipse 174" cx="0.958" cy="0.958" r="0.958" transform="translate(6.282 8.748)" fill="#7B7B7B" />
                                    <circle id="Ellipse_175" data-name="Ellipse 175" cx="0.958" cy="0.958" r="0.958" transform="translate(10.034 8.748)" fill="#7B7B7B" />
                                    <circle id="Ellipse_176" data-name="Ellipse 176" cx="0.958" cy="0.958" r="0.958" transform="translate(13.787 8.748)" fill="#7B7B7B" />
                                </g>
                            </svg>
                            <DatePickerComponant dateValue={this.state.fromDate} placeholder="From" selectionFunction={this.handlefrom} />
                        </div>
                    </div>
                    <div className="chat__date-wrap">
                        <div className="chat__date">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" className="chat__icon">
                                <g id="Group_1213" data-name="Group 1213" transform="translate(-4 -2.5)">
                                    <path id="Path_104" data-name="Path 104" d="M16.37,4.121H15.5v-.5a1.158,1.158,0,0,0-2.316,0v.5H8.829v-.5a1.158,1.158,0,0,0-2.316,0v.5H5.63A1.618,1.618,0,0,0,4,5.713v9.2A1.622,1.622,0,0,0,5.645,16.5H16.355A1.612,1.612,0,0,0,18,14.908v-9.2A1.6,1.6,0,0,0,16.37,4.121Zm-2.5-.5a.451.451,0,0,1,.457-.442.461.461,0,0,1,.457.442V4.666a.451.451,0,0,1-.457.442.461.461,0,0,1-.457-.442Zm-6.657,0a.457.457,0,0,1,.914,0V4.666a.457.457,0,0,1-.914,0Zm10.1,11.288a.925.925,0,0,1-.944.914H5.645a.934.934,0,0,1-.944-.914V7.186H17.314Z" transform="translate(0 0)" fill="#7B7B7B" />
                                    <circle id="Ellipse_171" data-name="Ellipse 171" cx="0.958" cy="0.958" r="0.958" transform="translate(6.282 12.388)" fill="#7B7B7B" />
                                    <circle id="Ellipse_172" data-name="Ellipse 172" cx="0.958" cy="0.958" r="0.958" transform="translate(10.034 12.388)" fill="#7B7B7B" />
                                    <circle id="Ellipse_173" data-name="Ellipse 173" cx="0.958" cy="0.958" r="0.958" transform="translate(13.787 12.388)" fill="#7B7B7B" />
                                    <circle id="Ellipse_174" data-name="Ellipse 174" cx="0.958" cy="0.958" r="0.958" transform="translate(6.282 8.748)" fill="#7B7B7B" />
                                    <circle id="Ellipse_175" data-name="Ellipse 175" cx="0.958" cy="0.958" r="0.958" transform="translate(10.034 8.748)" fill="#7B7B7B" />
                                    <circle id="Ellipse_176" data-name="Ellipse 176" cx="0.958" cy="0.958" r="0.958" transform="translate(13.787 8.748)" fill="#7B7B7B" />
                                </g>
                            </svg>
                            <DatePickerComponant dateValue={this.state.toDate} placeholder="To" selectionFunction={this.handleto} />
                        </div>
                    </div>


                </div>
                {this.state.isLoading ? this.renderLoader(this.state.messages) : ''}
                <div ref={(el) => { this.messagesEnd = el; }} style={{ float: "left", clear: "both" }}></div>
                <br />

            </main>

            {/*Input field*/}
            <footer style={{ backgroundColor: '#fff' }}>
                <div className="footer">
                    <input type="text"
                        style={{ display: this.state.user_input ? 'block' : 'none' }}
                        id="m"

                        maxLength="300"
                        placeholder="Send a message..."
                        className="footer__textarea"
                        onKeyPress={this.state.socketOn
                            ? this._handleSocketOn
                            : this._handleInputKeyPress}
                    />

                    <button
                        style={{ display: this.state.user_input ? 'block' : 'none' }}
                        type="button"
                        className="footer__send"
                        onClick={this.state.socketOn
                            ? this._handleSocketOnClick
                            : this._handleClick}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" >
                            <g id="Group_805" data-name="Group 805" transform="translate(-3636 -11015.273)">
                                <rect id="Rectangle_354" data-name="Rectangle 354" width="26" height="26" transform="translate(3636 11015.273)" fill="none" />
                                <g id="g948" transform="translate(3642.063 11020.292)">
                                    <path id="path929" d="M20.632,292.687l-11.9,6.85-7.612-5.363Z" transform="translate(-1.117 -292.687)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" className="slider__path" />
                                    <path id="path931" d="M14.6,292.687l-11.9,6.85.854,9.251Z" transform="translate(4.918 -292.687)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" className="slider__path" />
                                </g>
                                <g id="g944" transform="translate(3637.311 11027.275)">
                                    <path id="path933" d="M.4,296.549l4.2-2.416" transform="translate(0.875 -294.134)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" className="slider__path" />
                                    <path id="path935" d="M.133,299.236l8.013-4.611" transform="translate(-0.133 -292.254)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" className="slider__path" />
                                    <path id="path939" d="M1.087,297.741l4.2-2.416" transform="translate(3.516 -289.573)" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1" className="slider__path" />
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
                <div className="captchaMsg">Protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a> and
    <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer"> Terms of Service</a> apply.
    </div>
            </footer>
        </>
        )

    }
}
export default Chatbot;