import React from 'react';


const Message = (props) => {
let msg=props.text
let msg1=props.text[1]
  return (<>


    {props.speaks === `${process.env.REACT_APP_BOTICON}` && <div className="chat__left">
      <div className="bot bot--backcolor">
        <img src="./Images/chatbot.svg" alt="" className="bot__img bot__icon" />
        <img src="./Images/bot_1.svg" alt="" className="bot__img bot__real" />

      </div>
      {typeof(props.text)==='object' && props.text.length>1? <> <div className="chat__textwrap">
        <div className="chat__text chat__text--left">
          <span>
            {props.text[0]}
          </span>
        </div>
      </div><br /><br />
        <div className="chat__textwrap " style={{ marginLeft: '55px' }}>
          <div className="chat__text chat__text--left">
            <span>
            <div dangerouslySetInnerHTML={{ __html: msg1 }}></div>
            </span>
          </div>
         
        </div></> : 
        <div className="chat__textwrap">
          <div className="chat__text chat__text--left">
            <span>
           {msg}
            </span>
          </div>
          {/* <div className="chat__time">11:43 AM</div> */}
        </div>
        } 


    </div>}

    {props.speaks === `${process.env.REACT_APP_AGENTICON}` && <div className="chat__left">
      <div className="bot bot--backcolor">
        <img src="./Images/bot.svg" alt="" className="bot__img bot__icon" />
        <img src="./Images/real_pic.jpg" alt="" className="bot__img bot__real" />

      </div>

      <div className="chat__textwrap">
        <div className="chat__text chat__text--left">
          <span>
          <div dangerouslySetInnerHTML={{ __html: msg }}>
  
  </div>
            
          </span>
        </div>
        {/* <div className="chat__time">11:43 AM</div> */}
      </div>

    </div>}
    {props.speaks === `${process.env.REACT_APP_USERICON}` && (
      <div className="chat__right">
        <div className="chat__text chat__text--right">
          <p>
            {props.text}
          </p>
        </div>
        {/* <div className="chat__time">11:43 AM</div> */}
      </div>
    )}

  </>
  )
}

export default Message;