import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePickerComponant =(props)=>
 {
  // const ExampleCustomInput = ({ value, onClick }) => (
  //   <button className="chat__date-wrap" onClick={onClick}>
  //     {value}
  //   </button>
  // );
    return (
          <DatePicker
            selected={props.dateValue}
            onChange={props.selectionFunction}
            dateFormat="yyyy-MM-dd"
            maxDate={new Date()}
            placeholderText={props.placeholder}
          />
        );
      }
        
 
 export default DatePickerComponant;